<!--
 * @Author: SongYijie
 * @Date: 2020-03-09 09:42:27
 * @LastEditors: SongYijie
-->
<template>
  <div class="main has-header bg-gray">
    <mt-header fixed title="特别提示">
      <!-- <router-link to="" slot="left">
    <mt-button icon="back" @click.native="$router.back(-1)"></mt-button>
      </router-link>-->
    </mt-header>
    <div class="center">
      <img src="@assets/images/icon-hot-tip.png" width="200" alt />
      <div class="txt-black tips">
        您将在{{isXyk}}平台完成实名认证，
        <br />请确保您的身份信息真实有效
      </div>
    </div>
    <div class="footer-bt">
      <p class="warnning">严禁16周岁以下人员签约!</p>
      <mt-button type="danger" size="large" @click="checkFn">我已知晓</mt-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      value: "false",
      isXyk:window.location.origin.indexOf('h5.kylincloud-saas') > -1 ?'麒麟云服' :'僖游客'
    };
  },
  methods: {
    checkFn() {
      this.$router.replace("/user/selectArea");
    }
  }
};
</script>
<style lang='less' scoped>
.center {
  margin-top: 30%;
  width: 100%;
  height: 300px;
  text-align: center;
  .tips {
    font-size: 18px;
    line-height: 200%;
    font-family: PingFangSC-Semibold;
  }
}
.mint-radiolist {
  width: 150px;
  font-size: 14px;
  margin: auto;
}
.warnning {
  font-size: 12px;
  color: red;
  text-align: center;
  margin-bottom: 6px;
}
</style>
